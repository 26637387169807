import {CTAForm, ToastNotification} from '@prescriberpoint/ui';
import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import AuthFooter, {FooterCTA} from '../AuthFooter';
import AuthFormLayout from '../AuthFormLayout';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from './AgreeTermsAndCondtitionsAndPrivacyPolicy';
import {DEFAULT_TOAST_OPTIONS} from '@/components/ToastContainer';
import {E_USER_EXISTS} from '@/constants/global';
import {useUserAgentContext} from '@/context';
import {useAuthPath, useError, useIsProductAi} from '@/hooks';
import {APIError} from '@/services/request';
import {validateEmail, validateTextPresence} from '@/utils/validations';

interface AddEmailFormProps {
  onSubmit: (_e: any) => Promise<void>;
  error?: APIError;
  loginCallback?: () => void;
}

export default function AddEmailForm({
  onSubmit,
  error,
  loginCallback = () => null,
}: Readonly<AddEmailFormProps>) {
  const {loginPath} = useAuthPath();
  const isAi = useIsProductAi();
  const {errorMsg} = useError(error);

  const footerCTAs: FooterCTA[] = useMemo(
    () =>
      isAi
        ? [
            {
              label: 'Already have an account?',
              button: 'Log in',
              callback: () => loginCallback(),
            },
          ]
        : [
            {
              label: 'Already have an account?',
              href: loginPath,
              button: 'Log in',
            },
          ],
    [loginPath, isAi, loginCallback],
  );

  const {isMobileOrTablet} = useUserAgentContext();
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  useEffect(() => {
    if (error) {
      if (error.code === 409) {
        setInvalidEmailError(true);
      } else {
        setInvalidEmailError(false);
      }
      if (error.data.code === E_USER_EXISTS) {
        toast(
          <ToastNotification
            text='Email already exists'
            button={{
              id: 'login',
              text: 'Log in',
              hrefKey: loginPath,
            }}
          />,
          DEFAULT_TOAST_OPTIONS,
        );
      }
    }
  }, [error, isMobileOrTablet, loginPath]);

  const showError = useMemo(
    () => error && error.data.code !== E_USER_EXISTS,
    [error],
  );

  return (
    <AuthFormLayout>
      <CTAForm
        form={{
          submitText: 'Continue',
          isSubmittingText: 'Submitting...',
          inputs: [
            {
              id: 'email',
              type: 'email',
              label: 'Email',
              required: true,
              placeholder: 'Enter your business email',
              errorMessage: 'Please add a valid business email.',
              validateFn: (v: string) =>
                validateEmail(v) && validateTextPresence(v),
            },
          ],
        }}
        onSubmit={onSubmit}
        submitError={showError}
        submitErrorMsg={
          invalidEmailError ? 'Please add a valid business email.' : errorMsg
        }
      />

      <div>
        <AuthFooter callToActions={footerCTAs} />
        <AgreeTermsAndCondtitionsAndPrivacyPolicy />
      </div>
    </AuthFormLayout>
  );
}
