import {Button, customEvent, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useMemo, useState, useCallback} from 'react';
import AddEmailForm from '../AddEmail/AddEmailForm';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from '../AddEmail/AgreeTermsAndCondtitionsAndPrivacyPolicy';
import ConfirmEmail from '../AddEmail/ConfirmEmail';
import {FooterCTA} from '../AuthFooter';
import AuthHeader from '../AuthHeader';
import LoginForm from '../Login/LoginForm';
import {
  CEVENT_SIGNUP_REGISTER_EMAIL,
  DlvSignupRegisterEmail,
} from '@/constants';
import {useAuth} from '@/context/AuthProvider';
import {useRouter} from '@/hooks/useRouter';
import {useAddEmail} from '@/mutations';
import {getEmailDomain} from '@/utils/gtm';

const NewAuth = () => {
  const {query} = useRouter();
  const [authProcess, setAuthProcess] = useState('');
  const {login, error: loginError, isSuccess: isLoginSuccess} = useAuth();
  const {
    mutateAsync: addEmail,
    isSuccess: isSignupSuccess,
    reset: resetSignup,
    error: signupError,
    variables: signupVariables,
  } = useAddEmail();

  const footerCTAs: FooterCTA[] = useMemo(
    () => [
      {
        label: '',
        href: '/forgot-password',
        button: 'Forgot Password',
      },
      {
        label: "Don't have an account?",
        button: 'Sign up',
        callback: () => setAuthProcess('signup'),
      },
    ],
    [],
  );

  const handleSignUp = useCallback(
    async ({email}: any) => {
      customEvent<DlvSignupRegisterEmail>(CEVENT_SIGNUP_REGISTER_EMAIL, {
        emailDomain: getEmailDomain(email),
      });

      await addEmail({
        email,
        redirectUrl: query?.redirect as string,
        process: 'RegistrationAi',
      });
    },
    [addEmail, query],
  );

  const isSuccess = isSignupSuccess || query?.success === 'true';

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-1 items-center justify-center px-8 py-10 md:px-16',
        {
          'bg-neutral-primary': authProcess === '',
        },
      )}>
      {authProcess === '' ? (
        <div className='flex h-full w-full flex-col justify-between'>
          <div className='mb-4 flex w-full flex-1 flex-col items-center justify-center space-y-6 md:mb-0'>
            <Button
              id='login_btn'
              stretched
              size='xl'
              onClick={() => setAuthProcess('login')}
              variant='secondary'
              mode='dark'>
              Login
            </Button>
            <Button
              id='signup_btn'
              stretched
              size='xl'
              onClick={() => setAuthProcess('signup')}
              variant='primary'
              mode='dark'>
              Try it now
            </Button>
          </div>
          <AgreeTermsAndCondtitionsAndPrivacyPolicy alternativeTextColor />
        </div>
      ) : null}
      {authProcess === 'login' ? (
        <div className='flex h-full w-full flex-col space-y-12 pt-10 xl:pt-[7.25rem]'>
          <Text as='headline-lg' className='md:text-[44px] md:leading-[61.6px]'>
            Login
          </Text>
          <LoginForm
            onSubmit={login}
            // @ts-ignore
            errorMessage={loginError?.message as string}
            isLoading={isLoginSuccess}
            footerCtas={footerCTAs}
          />
        </div>
      ) : null}
      {authProcess === 'signup' && isSuccess ? (
        <ConfirmEmail
          email={signupVariables?.email! || (query.email as string)}
          onBack={resetSignup}
        />
      ) : null}
      {authProcess === 'signup' && !isSuccess ? (
        <div className='flex h-full flex-col'>
          <AuthHeader headline='Create free account' />
          <AddEmailForm
            onSubmit={handleSignUp}
            // @ts-ignore
            error={signupError}
            loginCallback={() => setAuthProcess('login')}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NewAuth;
