import {CTAForm, customEvent} from '@prescriberpoint/ui';
import {useRouter, useSearchParams} from 'next/navigation';
import {useCallback} from 'react';
import AuthFooter, {FooterCTA} from '../AuthFooter';
import AuthFormLayout from '../AuthFormLayout';
import {CEVENT_LOGIN, DlvLogin} from '@/constants';
import {getEmailDomain} from '@/utils/gtm';
import {validateEmail, validateTextPresence} from '@/utils/validations';

interface LoginFormProps {
  onSubmit: (_e: any) => Promise<any>;
  errorMessage?: string;
  isLoading?: boolean;
  footerCtas: FooterCTA[];
}

export default function LoginForm({
  onSubmit,
  errorMessage,
  isLoading,
  footerCtas,
}: Readonly<LoginFormProps>) {
  const router = useRouter();
  const searchParams = useSearchParams();

  const handleSubmit = useCallback(
    async (formData: any) => {
      const res = await onSubmit(formData);
      customEvent<DlvLogin>(CEVENT_LOGIN, {
        userId: res.userId,
        emailDomain: getEmailDomain(formData.email),
      });
      const redirect = searchParams?.get('redirect') ?? '/home';
      router.push(redirect);
    },
    [onSubmit, router, searchParams],
  );

  return (
    <AuthFormLayout>
      <CTAForm
        isLoading={isLoading}
        form={{
          submitText: 'Continue',
          isSubmittingText: 'Submitting...',
          inputs: [
            {
              id: 'email',
              type: 'email',
              label: 'Email',
              required: true,
              placeholder: 'Enter your business email',
              errorMessage: 'Please enter your valid business email.',
              validateFn: (v: string) =>
                validateEmail(v) && validateTextPresence(v),
            },
            {
              id: 'password',
              type: 'password',
              label: 'Password',
              required: true,
              placeholder: '********',
              errorMessage: 'Please enter your password.',
              validateFn: (v: string) => validateTextPresence(v),
            },
          ],
        }}
        onSubmit={handleSubmit}
        submitErrorMsg={errorMessage}
      />
      <AuthFooter callToActions={footerCtas} />
    </AuthFormLayout>
  );
}
