import {Icon, Text} from '@prescriberpoint/ui';
import AuthFooter, {FooterCTA} from '../AuthFooter';
import AuthFormLayout from '../AuthFormLayout';
import AuthHeader from '../AuthHeader';
import {useUserAgentContext} from '@/context';
import {useRouter} from '@/hooks/useRouter';
import {type RegistrationProcessType} from '@/models/addEmail';
import {useAddEmail} from '@/mutations/useAddEmail';

interface ConfirmEmailProps {
  email: string;
  onBack: () => void;
}

const RightIcon = () => (
  <Icon
    name='CheckmarkRegular'
    className='!ml-1 inline-flex !h-5 !w-5 items-center justify-center !rounded-full !bg-neutral-primary p-[5px] !text-white'
  />
);

export default function ConfirmEmail({
  email,
  onBack,
}: Readonly<ConfirmEmailProps>) {
  const {mutateAsync: resendEmail, isSuccess} = useAddEmail();
  const {isMobileOrTablet} = useUserAgentContext();
  const {query} = useRouter();
  const process = query?.process as RegistrationProcessType;

  const footerCTAs: FooterCTA[] = [
    {
      label: 'Didn’t receive the email?',
      callback: () => {
        resendEmail({email, process});
      },
      button: 'Send again',
    },
    !isSuccess
      ? {
          label: 'Take me back to',
          callback: onBack,
          button: 'Sign up',
        }
      : {
          label: 'Confirmation sent again!',
          button: '',

          rightIcon: RightIcon,
        },
  ];
  const setFontSize = () => (isMobileOrTablet ? 'sm' : 'lg');

  return (
    <AuthFormLayout>
      <AuthHeader
        headline='Confirm your email'
        body={
          <>
            <Text
              className='text-neutral-darker opacity-56'
              size={setFontSize()}>
              We have sent an email to <b>{email}</b>
            </Text>
            <Text
              className='text-neutral-darker opacity-56'
              size={setFontSize()}>
              Check your inbox to activate your account.
            </Text>
            <Text
              className='pt-6 text-neutral-darker opacity-56'
              size={setFontSize()}>
              {"If you don't see it within 2 minutes, check your spam inbox."}
            </Text>
          </>
        }
      />
      <AuthFooter callToActions={footerCTAs} />
    </AuthFormLayout>
  );
}
