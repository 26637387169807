import {Text, TextType} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {Fragment, ReactNode} from 'react';
import {useUserAgentContext} from '@/context';

export interface IOverline {
  text: string;
  icon?: ReactNode;
}

export interface AuthHeaderProps {
  overline?: IOverline;
  headline?: ReactNode | ReactNode[];
  body?: ReactNode;
  headlineAs?: TextType;
  headlineClassName?: ClassValue;
}

export default function AuthHeader({
  overline,
  headline,
  body,
  headlineAs = 'headline-lg',
  headlineClassName,
}: Readonly<AuthHeaderProps>) {
  const {isMobileOrTablet} = useUserAgentContext();

  return overline || headline || body ? (
    <div className="pb-4 pt-10">
      <div className="flex flex-col items-center space-y-2 break-words text-center md:items-start md:text-left">
        <div className="flex flex-col items-center justify-start md:items-start max-w-full w-full">
          <div className="flex items-center space-x-1">
            {overline?.text && (
              <Text as="overline-lg" casing="caps">
                {overline.text}
              </Text>
            )}
            {overline?.icon ? overline.icon : null}
          </div>
          {headline && (
            <Text
              as={headlineAs}
              weight="bold"
              className={clsx('w-full', headlineClassName)}>
              {Array.isArray(headline)
                ? headline.map((text, idx) => (
                    <Fragment key={text as string}>
                      {text}
                      {idx < headline.length - 1 && <br />}
                    </Fragment>
                  ))
                : headline}
            </Text>
          )}
        </div>
        {body &&
          (typeof body === 'string' ? (
            <Text
              as="body-md"
              size={isMobileOrTablet ? 'sm' : 'lg'}
              className="w-full space-y-2 text-neutral-darker text-opacity-56">
              {body}
            </Text>
          ) : (
            body
          ))}
      </div>
    </div>
  ) : null;
}
