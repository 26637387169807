import clsx from 'clsx';
import {FC, ReactNode} from 'react';

type AuthLayoutProps = {
  children: ReactNode;
  className?: string;
};

const AuthLayout: FC<AuthLayoutProps> = ({children, className}) => (
  <div
    className={clsx(
      'flex h-full flex-col overflow-y-auto px-6 pb-6 md:h-[calc(100vh-64px)] md:px-16 md:pb-10 ',
      className,
    )}>
    {children}
  </div>
);

export default AuthLayout;
