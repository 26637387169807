import {FC, ReactNode} from 'react';

interface AuthFormLayoutProps {
  children: ReactNode;
}
const AuthFormLayout: FC<AuthFormLayoutProps> = ({children}) => (
  <div className="flex h-full w-full flex-1 flex-col justify-between space-y-12">
    {children}
  </div>
);

export default AuthFormLayout;
